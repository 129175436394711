
.OnDeviceContainer{
    margin:20vh auto;
    display:flex;
    flex-direction:column;
    align-items:center;
}
.OnDeviceContainer h3{
    margin-right:auto;
    font-size: 2rem;
    font-weight: 600;
}
.OnDeviceContainer > p{
    font-size: 1rem;
    margin: 1rem 0 1.7rem 0;
    margin-right:auto;
}
.OnDeviceContainer .imageWrap{
    width:100vw;
    display:flex;
    background:rgba(214,238,240,.3);
}
.OnDeviceContainer .imageWrap img{
    margin:0 auto;
    width:100%;
    max-width: 1100px;
}