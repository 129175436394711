/* reset */
@font-face {
  font-family: 'Pretendard';
  src: url(../../public/font/Pretendard-Thin.otf);
  font-weight: 100;
}
@font-face {
  font-family: 'Pretendard';
  src: url(../../public/font/Pretendard-ExtraLight.otf);
  font-weight: 200;
}
@font-face {
  font-family: 'Pretendard';
  src: url(../../public/font/Pretendard-Light.otf);
  font-weight: 300;
}
@font-face {
  font-family: 'Pretendard';
  src: url(../../public/font/Pretendard-Regular.otf);
  font-weight: 400;
}
@font-face {
  font-family: 'Pretendard';
  src: url(../../public/font/Pretendard-Medium.otf);
  font-weight: 500;
}
@font-face {
  font-family: 'Pretendard';
  src: url(../../public/font/Pretendard-SemiBold.otf);
  font-weight: 600;
}
@font-face {
  font-family: 'Pretendard';
  src: url(../../public/font/Pretendard-Bold.otf);
  font-weight: 700;
}
@font-face {
  font-family: 'Pretendard';
  src: url(../../public/font/Pretendard-ExtraBold.otf);
  font-weight: 800;
}
@font-face {
  font-family: 'Pretendard';
  src: url(../../public/font/Pretendard-Black.otf);
  font-weight: 900;
}
@font-face {
  font-family: 'SCDream';
  src: url(../../public/font/SCDream9.otf);
  font-weight: 900;
}

* {box-sizing:border-box;}
html {
  font-size: 16px;
}
html,body,h1,h2,h3,h4,h5,h6,
p,div,ol,ul,li,dl,dt,dd,address,blockquote,video,
em,strong,span,sup,sub,del,s,q,a,img,
table,thead,tbody,tfoot,th,tr,td,
form,fieldset,legend,input,select,option,button,textarea,label,optgroup,
header,main,footer,nav,section,article,aside,figure,figcaption,details
{
    font-family: "Pretendard";
    color:#000; letter-spacing:0.02em;
    font-weight:500; font-size: 1rem; font-style:normal;
    line-height:1.2; margin:0; padding:0;
    border:0; background:none; word-break: keep-all;
}
ul,ol,li {list-style:none;}
a {text-decoration:none; color:#111;}
table,tr,td,th {border-collapse:collapse;text-align:left;}
button {cursor:pointer;}
.skip {display:none;}
input {
  outline: none;
}

@media screen and (max-width: 768px) {
  html
  {
    font-size: 14px !important;
  }
}