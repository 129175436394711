header {
  background-color: #001530;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  margin-bottom: 60px;
  padding: 0px 5vw;
  position: relative;
}
header h1 {
  height: 100%;
  padding: 0.72rem 0;
}
header h1 a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-weight: 900;
  color: #fff;
  height: 100%;
  line-height: 1.2;
}
header h1 img {
  height: 100%;
  margin-right: .5rem;
}

header .rightWrap {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
header .searchWrap { 
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  font-size: 1.2rem;
  color: #888888;
  background-color: #fff;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, .2);
  border-radius: 90px;
  padding: .4rem;
}
header .searchWrap input {
  width: 100%;
  padding-right: 1rem;
  margin-left: 2rem;
  font-size: 0.875rem;
}
header .searchWrap span {
  display: inline-block;
  background: linear-gradient(90deg, rgba(85,191,200,1) 0%, rgba(3,64,105,1) 100%);
  border-radius: 100%;
  width:40px; height:40px;
}
header .searchWrap span .searchBtn {
  filter: drop-shadow(2px 2px 2px rgba(0,0,0,.5));
  scale: .5;
}
header .mSearchBtn {
  display: none;
}
header .mSearchWrap.active {
  opacity: 1;
  z-index: 1;
}
header .mSearchWrap {
  position: absolute;
  z-index: -1;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  bottom: -60px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  font-size: 1.2rem;
  color: #888888;
  background-color: #fff;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, .2);
  border-radius: 90px;
  padding: .4rem;
  transition: all .5s;
}
header .mSearchWrap input {
  width: 100%;
  padding-right: 1rem;
  margin-left: 2rem;
  font-size: 1.2rem;
}
header .mSearchWrap span {
  display: inline-block;
  background: linear-gradient(90deg, rgba(85,191,200,1) 0%, rgba(3,64,105,1) 100%);
  border-radius: 100%;
}
header .mSearchWrap span img {
  filter: drop-shadow(2px 2px 2px rgba(0,0,0,.5));
  scale: .6;
}

nav.active {
  padding-bottom: 8rem;
  max-height: 350px;
}
nav {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  z-index: 9;
  background: linear-gradient(90deg, rgba(85,191,200,1) 0%, rgba(3,64,105,1) 100%);
  max-height: 60px;
  overflow: hidden;
  transition: all .5s ease;
}
nav .navWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 500px;
  margin: 0 auto;
}
nav .navWrap > li {}
nav .navWrap > li p {
  margin-bottom: 2rem;
  line-height: 62px;
}
nav .navWrap > li a {
  font-size: 1.125rem;
  color: #fff;
  font-weight: 500;
  margin-bottom: 1rem;
  cursor:pointer;
}
nav .navWrap .navContents {}
nav .navWrap .navContents > li:last-child {
  margin-bottom: 0;
}
nav .navWrap .navContents > li {
  margin-bottom: 1.2rem;
}
nav .navWrap .navContents > li a {
  font-size: 1.25rem;
}


/* ------------------------------메뉴버거바 */
header .nav {
  display: none;
  margin-left: 1rem;
}
.burger-icon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  z-index: 2;
  padding: 0.5rem 0;
  left: 0;
  /* bottom: -0.188rem; */
  user-select: none;
  width: auto;
  margin: 0;
}

.burger-icon .burger-sticks {
display: block;
height: 2px;
position: relative;
transition: background .2s ease-out;
width: 40px;
background-color: #fff;
/* box-shadow: 0 .1rem .1rem rgba(0, 0, 0, .25); */
}

.burger-icon .burger-sticks:before,
.burger-icon .burger-sticks:after {
background: #fff ;
content: '';
display: block;
height: 100%;
position: absolute;
transition: all .2s ease-out;
width: 100%;
/* box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, .25); */
}
.burger-icon .burger-sticks:before {
top: 12px;
}

.burger-icon .burger-sticks:after {
top: -12px;
}

.burger-check {
display: none;
}

.burger-check:checked~.menu {
max-width: 130px;
}

.burger-check:checked~.burger-icon .burger-sticks {
background: transparent;
box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0);
}

.burger-check:checked~.burger-icon .burger-sticks:before {
transform: rotate(-45deg);
box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0);
/* background-color: #2B2B2B; */
}

.burger-check:checked~.burger-icon .burger-sticks:after {
transform: rotate(45deg);
box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0);
/* background-color: #2B2B2B; */
}

.burger-check:checked~.burger-icon .burger-sticks:before,
.burger-check:checked~.burger-icon .burger-sticks:after{
  /* background: #fff !important; */
}


.burger-check:checked~.burger-icon:not(.steps) .burger-sticks:before,
.burger-check:checked~.burger-icon:not(.steps) .burger-sticks:after {
top: 0;
}

@media screen and (max-width:1024px) {
  html {
    font-size: 14px;
  }
  header {
    margin: 0;
  }
  header .nav {
    display: block;
  }
  nav.active {
    max-height: 100%;
    padding: 1rem 1rem 5rem;
  }
  nav {
    max-height: 0;
  }
  nav .navWrap {
    flex-flow: row wrap;
  }
  nav .navWrap > li {
    width: 50%;
  }
  nav .navWrap > li p {
  }
  nav .navWrap > li a {
  }
  nav .navWrap .navContents {}
  nav .navWrap .navContents > li:last-child {
  }
  nav .navWrap .navContents > li {
  }
  nav .navWrap .navContents > li a {
  }
  nav .navWrap {
    flex-flow: column nowrap;
    align-items: center;
  }
  nav .navWrap > li p {
    text-align: center;
  }
  nav {
    padding: 0 !important;
  }

}

@media screen and (max-width:768px) {
  header {
    height: 80px;
  }
  header .searchWrap {
    display: none;
  }
  header .mSearchBtn {
    display: block;
    height: 100%;
  }
  header .rightWrap {
    width: auto;
  }
  nav {
    top: 80px !important;
  }
}