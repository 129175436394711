#notice {
  padding: 6rem 5vw;
}
#notice h2 {
  font-size: 3.75rem;
  font-weight: 700;
  text-align: center;
}

#notice .categoryWrap {
  display: flex;
  justify-content: flex-end;
  margin: 3rem 0;
}
#notice .customSelect {
  width: 200px; /* select와 동일한 너비 */
  height: 50px; /* select와 동일한 높이 */
  background-color: #fff; /* 배경색 */
  border: 1px solid #aaa; /* 테두리 */
  border-radius: 100px; /* 테두리 둥글기 */
  padding: 0 1rem; /* 패딩 */
  cursor: default; /* 마우스 커서 모양 */
  display: flex; /* 내부 요소 정렬을 위한 flexbox 사용 */
  align-items: center; /* 수직 중앙 정렬 */
  position: relative; /* dropdown 위치 설정을 위한 relative */
  outline: none; /* outline 제거 */
}

#notice .selectedOption {
  color: #aaa; /* 기본 카테고리 텍스트 색상 */
  font-size: 1.2rem; /* 글자 크기 */
  flex-grow: 1; /* 남은 공간을 차지하도록 설정 */
}

#notice .dropdown {
  position: absolute; /* 드롭다운 위치 */
  width: 100%; /* 부모와 동일한 너비 */
  background-color: #fff; /* 드롭다운 배경색 */
  border: 1px solid #aaa; /* 드롭다운 테두리 */
  border-radius: 10px; /* 드롭다운 둥글기 */
  max-height: 150px; /* 최대 높이 설정 */
  overflow-y: auto; /* 내용이 넘칠 때 스크롤 가능 */
  z-index: 10; /* z-index 설정 */
  top: 50px; /* 드롭다운 위치 */
  left: 0;
}

#notice .dropdown li {
  padding: 10px; /* 드롭다운 항목 패딩 */
  list-style: none; /* 목록 스타일 제거 */
  cursor: pointer; /* 커서 모양 */
  color: #222; /* 선택된 옵션 텍스트 색상 */
}
#notice .dropdownImg {
  height: 20%;
}

#notice .dropdown li:hover {
  background-color: #f1f1f1; /* 마우스 오버 시 배경색 변경 */
}


#notice .categoryWrap select option {}
#notice .categoryWrap .searchWrap {
  max-width: 350px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  font-size: 1.2rem;
  color: #aaa;
  background-color: #fff;
  outline: 1px solid #aaa;
  border-radius: 90px;
  margin-left: 1rem;
}
#notice .categoryWrap .searchWrap input::placeholder {
  color: #aaa;
}
#notice .categoryWrap .searchWrap input {
  width: 100%;
  padding-right: 1rem;
  margin-left: 1rem;
  font-size: 1.2rem;
}
#notice .categoryWrap .searchWrap button {
  border-radius: 100%;
  outline: 1px solid #aaa;
  width: 50px;
  height: 50px;
  text-align: center;
}
#notice .categoryWrap .searchWrap button img {
  scale: 0.5;
  height: 100%;
}



#notice table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%; /* 테이블 전체 너비를 100%로 설정 */
}

#notice section.notice {
  padding: 80px 0;
}

#notice .board-table {
  font-size: 13px;
  width: 100%;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  table-layout: fixed; /* 테이블 레이아웃을 고정 */
}

#notice .board-table a {
  color: #333;
  display: block;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
}

#notice .board-table a:hover {
  text-decoration: underline;
}

#notice .board-table th,
#notice .board-table td {
  padding: 14px 0;
  text-align: center;
  overflow: hidden; /* 넘치는 내용 숨김 */
  text-overflow: ellipsis; /* 넘치는 내용 줄임표 처리 */
  white-space: nowrap; /* 줄바꿈 없이 한 줄로 표시 */
}

#notice .board-table th {
  text-align: center;
}

#notice .board-table .th-num,
#notice .board-table .th-field,
#notice .board-table .th-file,
#notice .board-table .th-inquiry,
#notice .board-table .th-date {
  width: 10%;
}

#notice .board-table .th-title {
  width: 50%;
}

#notice .board-table tbody .title {
  text-align: start;
  padding: 0 .5rem;
}

#notice .board-table tbody td {
  border-top: 1px solid #e7e7e7;
  text-align: center;
  overflow: hidden; /* 넘치는 내용 숨김 */
  text-overflow: ellipsis; /* 넘치는 내용 줄임표 처리 */
  white-space: nowrap; /* 줄바꿈 없이 한 줄로 표시 */
}


.page .pagination li {
  display: inline-block;
  padding: 10px;
  cursor: pointer;
}

.page .pagination li.active {
  color: coral; /* 선택된 페이지는 coral 색상 */
}

.page .prev, .page .next {
  padding: 10px;
}
