.conect .sendWrap{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, .3);
} 
.conect .sendWrap .infoWrap * {
  color: #fff;
}
.conect .sendWrap .infoWrap{
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 50%;
  background: linear-gradient(90deg, rgba(85,191,200,1) 0%, rgba(0,121,203,1) 100%);
  padding: 5vw 3vw 3vw 3vw;
} 
.conect .sendWrap .infoWrap .top{} 
.conect .sendWrap .infoWrap .top p{
} 
.conect .sendWrap .infoWrap .top p.secondTxt{
  font-size:1.25rem;
  line-height:2rem;
} 
.conect .sendWrap .infoWrap .top .firstTxt{
  margin-bottom: 10%;
  font-size: 3rem;
  font-weight: 700;
} 
.conect .sendWrap .infoWrap .to p .secondTxt{
  font-size: 1.3rem;
  line-height: 1.5;
} 
.conect .sendWrap .infoWrap .bottom{
  display: flex;
} 
.conect .sendWrap .infoWrap .bottom input[type="checkbox"]{
  margin-right:10px;
} 
.conect .sendWrap .infoWrap .bottom p {}
.conect .sendWrap .infoWrap .bottom p button {
  font-size: .8rem;
}

.conect .sendWrap .inputWrap{
  width: 50%;
  height: 100%;
  padding: 3vw 2vw;
} 
.conect .sendWrap .inputWrap ul{
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1vw;
  width: 100%;
  height: calc(100% - 5vh);
} 
.conect .sendWrap .inputWrap ul li{
  width: 100%;
} 
.conect .sendWrap .inputWrap ul li:last-child{
  height: 100%;
}
.conect .sendWrap .inputWrap ul li:last-child .writWrap{
  height: 80%;
}
.conect .sendWrap .inputWrap ul li .title{
  font-weight: 400;
  margin-bottom: 5px;
} 
.conect .sendWrap .inputWrap ul li .title .redStar{
  color: red;
} 
.conect .sendWrap .inputWrap ul li .warning_txt{} 
.conect .sendWrap .inputWrap ul li .writWrap{} 
.conect .sendWrap .inputWrap ul li .writWrap input,
.conect .sendWrap .inputWrap ul li .writWrap textarea{
  border: 1px solid #BBBBBB;
  padding: 1rem;
  width: 100%;
  border-radius: 3px;
} 
.conect .sendWrap .inputWrap ul li .writWrap textarea{
  height: 100%;
  resize: none;
}
.conect .sendWrap .inputWrap .sendBtn{
  background: linear-gradient(90deg, rgba(85,191,200,1) 0%, rgba(0,121,203,1) 100%);
  color: #fff;
  font-weight: 700;
  text-align: center;
  height: 5vh;
  font-size: 1.4rem;
  line-height: 5vh;
  border-radius: 3px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, .2);
  cursor: pointer;
  margin-top: 2vw;
} 



.conect .mapWrap{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 50vh;
  margin: 20vh auto;
} 
.conect .mapWrap .addressWrap{
  display: flex;
  flex-flow: column nowrap;
  gap: 3vh;
} 
.conect .mapWrap .addressWrap li{
  display: flex;
  justify-content: flex-start;
  align-items: center;
} 
.conect .mapWrap .addressWrap li .icon {
  margin-right: 1rem;
} 
.conect .mapWrap .addressWrap li .icon img{
  height: 70px;
  display: block;
} 
.conect .mapWrap .addressWrap li .adTxt{
  height: 70px;
  font-size: 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  color: #666;
  font-weight: 400;
  align-items: flex-start;
} 
.conect .mapWrap .addressWrap li .adTxt span{
  font-size: 1.5rem;
  font-weight: 600;
  color: #666;
}
.conect .mapWrap .map{
  width: 50%;
  height: 100%;
}
.conect .mapWrap .map iframe{
  width: 100%;
  height: 100%;
}

@media screen and (max-width:1024px){
  .conect .mapWrap {
    flex-flow: column nowrap;
    height: auto;
  }
  .conect .mapWrap .addressWrap {
    width: 100%;
  }
  .conect .mapWrap .addressWrap li .icon img {
    height: 50px;
  }
  .conect .mapWrap .addressWrap li .adTxt {
    height: auto;
    font-size: 1rem !important;
  }
  .conect .mapWrap .addressWrap li .adTxt * {
    font-size: 1rem !important;
  }
  .conect .mapWrap .map {
    margin-top: 2rem;
    width: 100%;
    height: 500px;
  }
}
@media screen and (max-width:768px){
  .conect .sendWrap {
    flex-flow: column nowrap;
  }
  .conect .sendWrap > div {
    width: 100% !important;
  }
  .conect .sendWrap .inputWrap {
    padding: 5vw 2vw;
  }
  .conect .sendWrap .infoWrap .top .firstTxt {
    font-size: 2.5rem;
  }
  .conect .sendWrap .infoWrap .bottom {
    margin-top: 2rem;
  }

  .conect .mapWrap .map {
    margin-top: 2rem;
    width: 100%;
    height: 300px;
  }

}