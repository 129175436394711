*{
    color:#434343;
}

.Admin header{
}
.Admin header h1 a{
    color:#434343;
    text-shadow:none;
}
.Admin header h4 a{
    color:#434343;
    text-shadow:none;
}
.Admin .left_div {
    width:225px;
}

.Admin .left_div .leftMenu{
    left:auto; top:auto;
    background:none;
    max-height:none;
    overflow:auto;
    transition:none;
}

.Admin .left_div .menu_list_div{
    width:225px;
    max-width: 360px;
    padding:25px;
}
.Admin .left_div .menu_list_div h4{
    margin:10px 0;
    font-weight:600;
}

.Admin .left_div{
    
}

.Admin .right_div{
    width:955px;
    height:880px;
    padding:25px;
}


.Admin .wrap{
    margin:0 auto;
    display:flex;
    width:1200px;
    margin-top:100px;
}

.Admin .right_div h4{
    font-weight:600;
    margin:30px 0;
}