#visual {
  padding: 6rem 5vw;
}
#visual h2 {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
}
#visual .categoryWrap {
  display: flex;
  justify-content: flex-end;
  margin: 3rem 0;
}
#visual .customSelect {
  width: 200px; /* select와 동일한 너비 */
  height: 50px; /* select와 동일한 높이 */
  background-color: #fff; /* 배경색 */
  border: 1px solid #aaa; /* 테두리 */
  border-radius: 100px; /* 테두리 둥글기 */
  padding: 0 1rem; /* 패딩 */
  cursor: default; /* 마우스 커서 모양 */
  display: flex; /* 내부 요소 정렬을 위한 flexbox 사용 */
  align-items: center; /* 수직 중앙 정렬 */
  position: relative; /* dropdown 위치 설정을 위한 relative */
  outline: none; /* outline 제거 */
}

#visual .selectedOption {
  color: #aaa; /* 기본 카테고리 텍스트 색상 */
  font-size: 1.2rem; /* 글자 크기 */
  flex-grow: 1; /* 남은 공간을 차지하도록 설정 */
}

#visual .dropdown {
  position: absolute; /* 드롭다운 위치 */
  width: 100%; /* 부모와 동일한 너비 */
  background-color: #fff; /* 드롭다운 배경색 */
  border: 1px solid #aaa; /* 드롭다운 테두리 */
  border-radius: 10px; /* 드롭다운 둥글기 */
  max-height: 150px; /* 최대 높이 설정 */
  overflow-y: auto; /* 내용이 넘칠 때 스크롤 가능 */
  z-index: 10; /* z-index 설정 */
  top: 50px; /* 드롭다운 위치 */
  left: 0;
}

#visual .dropdown li {
  padding: 10px; /* 드롭다운 항목 패딩 */
  list-style: none; /* 목록 스타일 제거 */
  cursor: pointer; /* 커서 모양 */
  color: #222; /* 선택된 옵션 텍스트 색상 */
}
#visual .dropdownImg {
  height: 20%;
}

#visual .dropdown li:hover {
  background-color: #f1f1f1; /* 마우스 오버 시 배경색 변경 */
}


#visual .categoryWrap select option {}
#visual .categoryWrap .searchWrap {
  max-width: 350px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  font-size: 1.2rem;
  color: #aaa;
  background-color: #fff;
  outline: 1px solid #aaa;
  border-radius: 90px;
  margin-left: 1rem;
}
#visual .categoryWrap .searchWrap input::placeholder {
  color: #aaa;
}
#visual .categoryWrap .searchWrap input {
  width: 100%;
  padding-right: 1rem;
  margin-left: 1rem;
  font-size: 1.2rem;
}
#visual .categoryWrap .searchWrap button {
  border-radius: 100%;
  outline: 1px solid #aaa;
  width: 50px;
  height: 50px;
  text-align: center;
}
#visual .categoryWrap .searchWrap button img {
  scale: 0.5;
  height: 100%;
}





.visualWrap ul {
  margin: 0 auto;
  max-width: 1600px;
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 20px;
}
.visualWrap ul li {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  width: calc(50% - 10px);
  height: 30vw;
  max-height: 500px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: background-color 0.3s ease;
}
.visualWrap ul li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease;
}
.visualWrap ul li:hover img {
  transform: scale(1.05); /* hover 시 이미지 확대 */
}
/* 타이틀과 링크를 함께 처리 */
.visualWrap ul li div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5); /* hover 시 배경색 */
  opacity: 0;
  transition: opacity 0.3s ease;
}
/* hover 시 타이틀과 링크가 나타남 */
.visualWrap ul li:hover div {
  opacity: 1;
}
.visualWrap ul li p {
  margin: 10px 0;
  font-size: 3.125rem;
  font-weight: 700;
}
.visualWrap ul li a {
  font-size: 1.2rem;
  font-weight: 700;
  text-decoration: none;
  padding: .6rem 2rem;
  border-radius: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: rgba(0, 0, 0, 0.3);
}
.visualWrap ul li a:hover {
  background-color: #fff;
  color: #000;
}


@media screen and (max-width:1024px){
  
}
@media screen and (max-width:768px){
  #visual h2 {
    font-size: 10vw;
  }
  #visual .categoryWrap {
    flex-flow: column nowrap;
    align-items: flex-end;
    gap: .5rem;
  }
  #visual .categoryWrap .searchWrap {
    width: 100%;
  }
  .visualWrap ul li {
    width: 100%;
    height: 50vw;
  }
  .visualWrap ul li p {
    margin: 10px 0;
    font-size: 2.5rem;
  }
}