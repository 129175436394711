
.businessModelContainer{
  margin:20vh auto;
    display:flex;
    flex-direction:column;
    align-items:center;
}
.businessModelContainer h3{
    margin-bottom:10px;
    margin-right:auto;
    font-size: 2rem;
    font-weight: 600;
}
.businessModelContainer > p{
    font-size: 1rem;
    margin: 1rem 0;
}

.businessModelContainer img {
    width:100%;
}