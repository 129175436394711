#graph {
  max-width: 1440px;
  margin: 0 auto;
  padding: 6rem 0;
}

#graph h2 {
  font-size: 2rem;
  font-weight: 600;
  padding: 2.4rem 0;
  text-align: center;
  border-top: 3px solid #3B383E;
  border-bottom: 1px solid #3B383E;
}
#graph .dataWrap {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
}
#graph .dataWrap p:nth-child(2) {
  border-right: 1px solid #3B383E;
  border-left: 1px solid #3B383E;
}
#graph .dataWrap p {
  padding: 0 1rem;
  color: #3B383E;
}
#graph .dataWrap p span {}



#graph .categoryWrap {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #C9C6C5;
  border-bottom: 1px solid #C9C6C5;
  padding: 1rem;
}
#graph .categoryWrap p {
  border-radius: 50px;
  font-size: 1.2rem;
  border: 1px solid #C9C6C5;
  height: 2.6rem;
  line-height: 30px;
  display: flex;
  align-items: center;
  padding: .5rem;
  justify-content: center;
}
#graph .categoryWrap p img {
  height: 100%;
  margin-right: .3rem;
}

#myChart {
  width: 90vw !important;
  max-width: 1200px;
  margin: 5rem auto;
}

#graph .graphList {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  height: 5rem;
  margin-top: 3rem;
}

#graph .graphWrap .iframeWrap {
  width:1400px; height: 800px;
  overflow:hidden;
}

#graph .graphWrap .iframeWrap iframe{
  width:1400px; height: 800px;
}


#graph .slider-container {
  height: 100%;
  position: relative;
  border: 1px solid #C9C6C5;
  border-radius: 50px;
  width: 50%;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}
#graph .slider {
  width: 70%;
  margin: 0 auto;
}
#graph .slide {
  display: flex !important;
  justify-content: center;
  position: relative;
}
#graph .slide span {
  position: absolute;
  left: 50% ;
  top: 50%;
  transform: translate(-50%, -50%);
}
#graph .prev img {
  rotate: 90deg;
  scale: .5;
}
#graph .next img {
  rotate: -90deg;
  scale: .5;
}
#graph .prev,
#graph .next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
}
#graph .prev {
  left: 1rem;
}
#graph .next {
  right: 1rem;
}
#graph .listBtn {
  border: 1px solid #10919D;
  border-radius: 50px;
  width: 10%;
  height: 100%;
  line-height: 5rem;
  text-align: center;
}
#graph .listBtn a {
  color: #10919D;
  width: 100%;
  height: 100%;
  display: block;
  line-height: 5rem;
}

/* 표 */
.cellContainer {
  border-top: 3px solid #3B383E;
  margin-top: 5rem;
}
.cellContainer * {
  color: #3B383E;
}
.cellContainer ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch; /* 각 li의 자식 요소들이 동일한 높이가 되도록 설정 */
}
.cellContainer ul li:last-child .cellTitle {
  width: 15%;
}
.cellContainer ul li:last-child .cellInfo {
  width: 85%;
}
.cellContainer ul li:last-child {}
.cellContainer ul li:last-child {
  width: 100%;
  line-height: 1.5;
}
.cellContainer ul li {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch; /* li 내의 자식 요소들이 동일한 높이가 되도록 설정 */
  border-bottom: 1px solid #C9C6C5;
}
.cellContainer ul li .cellTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.25rem;
  font-weight: 600;
  background-color: #eee;
  width: 30%;
  padding: 2vw 1vw;
  text-align: center;
}
.cellContainer ul li .cellInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  padding: 2vw 1vw;
}



@media screen and (max-width:1500px) {
  #graph{
    padding: 5vw;
  }
}

@media screen and (max-width: 768px) {
  #graph .graphList {
    flex-flow: column nowrap;
  }
  .slider-container {
    margin: 0 0 1rem 0 !important;
    padding: .2rem;
    width: 100% !important;
  }
  .listBtn {
    width: 100% !important;
  }
  .cellContainer ul li {
    width: 100%;
  }
  .cellContainer ul li:last-child .cellTitle {
    width: 30%;
  }
  .cellContainer ul li:last-child .cellInfo {
    width: 70%;
  }
}