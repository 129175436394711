footer {
  background-color: #001530;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4.375rem 5vw;
}
footer .logo {
  margin-right: 3rem;
  height: 30vh;
}
footer .logo img {
  height: 100%;
}

footer div {}
footer div .company {
  color: #fff;
  font-size: 1.625rem;
}
footer div ul {
  display: flex;
  margin: 1.5rem 0;
}
footer div ul li:first-child {
  padding-left: 0;
}
footer div ul li:last-child {
  padding-right: 0;
  border: none;
}
footer div ul li {
  padding: 0 2rem;
  border-right: 3px solid #C0C0C0;
}
footer div ul li a {
  font-size: 1.625rem;
  color: #fff;
  word-break: keep-all;
}
footer div .address {
  line-height: 1.5;
  font-size: 1.4rem;
  color: #ABABAB;
}

@media screen and (max-width:1024px) {
  footer .logo {
    height: 30vw;
  }
  footer div ul {
    flex-flow: column nowrap;
  }
  footer div ul li {
    border: none;
    padding: .4rem 0;
  }
}

@media screen and (max-width:768px){
  footer * {
    font-size: 14px !important;
  }
}