
.ExperienceContainer h3{
  font-size: 2rem;
  font-weight: 600;
}

.ExperienceContainer .cubeInfo{
  margin-top:2rem;
}
.ExperienceContainer .cubeInfo > p{
  font-size: 2rem;
  margin-bottom: 1rem;
}
.ExperienceContainer .cubeInfo ul{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  gap: 2rem;
}
.ExperienceContainer .cubeInfo ul li:nth-child(1){
  background-image: url(../../public/img/ExBg1.png);
}
.ExperienceContainer .cubeInfo ul li:nth-child(2){

}
.ExperienceContainer .cubeInfo ul li:nth-child(3){
  background-image: url(../../public/img/ExBg2.png);
  flex-flow: row-reverse;
}
.ExperienceContainer .cubeInfo ul li{
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  border-radius: 1.8rem;
  overflow: hidden;
}

.ExperienceContainer .cubeInfo ul li:nth-child(2) .imagesCon{
  display: flex;
  justify-content: center;
  align-items: center;
}
.ExperienceContainer .cubeInfo ul li:nth-child(2) .imagesCon img{
  width: calc(50% - 2px);
}

.ExperienceContainer .cubeInfo ul li:nth-child(2) .imagesCon .line{
  width:2px; height:100%;
  background:#0470b8;
}


.ExperienceContainer .cubeInfo ul li:nth-child(3) .infoImg {
  box-shadow: -5px 0px 5px rgba(0, 0, 0, .3);
}
.ExperienceContainer .cubeInfo ul li .infoImg {
  width: 37%; /* 원하는 너비 */
  box-shadow: 5px 0px 5px rgba(0, 0, 0, .3);
}
.ExperienceContainer .cubeInfo ul li:last-child .infoTxt span,
.ExperienceContainer .cubeInfo ul li:last-child .infoTxt {
  text-align: end;
}
.ExperienceContainer .cubeInfo ul li .infoTxt {
  width: 63%;
  text-align: start;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  color: #585858;
  font-size: 1rem;
  padding: 4vw;
  line-height: 1.5;
}
.ExperienceContainer .cubeInfo ul li > p{
}
.ExperienceContainer .cubeInfo ul li p img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ExperienceContainer .cubeInfo ul li span{
  width: 100%;
  margin-bottom: 2vw;
  font-weight: 700;
  font-size: 1.5rem;
}

@media screen and (max-width:768px) {
  .ExperienceContainer .cubeInfo ul li , .imagesCon {
    flex-flow: column nowrap !important;
  }
  .ExperienceContainer .cubeInfo ul li > p {
    width:  100% !important;
  }
  .ExperienceContainer .cubeInfo ul li:nth-child(2) .imagesCon .line {
    width: 100%;
    height: 2px;
  }
  .ExperienceContainer .cubeInfo ul li:nth-child(2) .imagesCon img{
    width: 100%;
  }
}