
.ServiceContainer h3{
  font-size: 5rem;
  font-weight: 600;
}
.ServiceContainer > p{
  font-size: 2rem;
  margin: 1rem 0;
}
.ServiceContainer > ul{
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;
}
.ServiceContainer > ul > li{
  width: 25%;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-size: 1.1rem;
  padding: 4rem 0;
}
.ServiceContainer > ul > li img{
  width: 10vw;
}
.ServiceContainer > ul > li span{
  font-size: 2rem;
  margin: 2rem 0 1rem;
}

.ServiceContainer .cubeInfo{
  margin: 20vh auto;
}
.ServiceContainer .cubeInfo > p{
  font-size: 2rem;
  margin-bottom: 1rem;
}
.ServiceContainer .cubeInfo ul{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;
}
.ServiceContainer .cubeInfo ul li{
  width: 49.5%;
  background-color: #F1F9FA;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 1.8rem;
  overflow: hidden;
  margin-bottom: 1rem;
}
.ServiceContainer .cubeInfo ul li:first-child{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.ServiceContainer .cubeInfo ul li .nameWrap {
  color: #001530;
  height: auto;
  font-weight: 700;
  font-size: 5vw;
  margin: 0 0 5% 5%;
}

.ServiceContainer .cubeInfo ul li .infoImg {
}
.ServiceContainer .cubeInfo ul li .infoTxt {
  text-align: start;
  background-color: #F1F9FA;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  color: #222;
  font-size: 0.875rem;
  padding: 1.2rem;
  line-height: 1.5;
  text-align: center;
}
.ServiceContainer .cubeInfo ul li > p{
  height: 65%;
  width: 100%;
}
.ServiceContainer .cubeInfo ul li p img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ServiceContainer .cubeInfo ul li .color{
  color: #027acb;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
}

.ServiceContainer .cubeInfo ul li span{
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
}

@media screen and (max-width:768px) {
  .ServiceContainer .cubeInfo ul {
    flex-flow: column nowrap;
  }
  .ServiceContainer .cubeInfo ul li {
    width: 100%;
  }
}