html {overflow-x: hidden;}
.liveWrap, .businessContainer, .businessModelContainer,
.OnDeviceContainer, .ExperienceContainer, .ServiceContainer, .conect {
  margin: 0 auto;
  max-width: 1440px;
}
#main {}
/* BnrBusiness */
.mainBnr{
  height: 80vh;
  background-image: url(../../public/img/mainBnr.png);
  background-size: cover;
  position: relative;
  padding: 10vw 5vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.mainBnr h2{
  font-size: 5rem;
  font-weight: 900;
  color: #fff;
  margin-bottom: 5vh;
  line-height: 1.1;
}
.mainBnr > p{
  font-size: 1.25rem;
  color: #fff;
  line-height: 1.8;
  max-width: 50vw;
}
.mainBnr .actionWrap{
  width: 100%;
  height: 20vh;
  max-height: 200px;
  padding: 0 5vw;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: -5vw;
  left: 50%;
  transform: translateX( -50%);
}
.mainBnr .actionWrap .keyWrap{
  height: 100%;
  width: 63.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 3px solid transparent;
  border-radius: .8rem;
  background-image: linear-gradient(#fff, #fff), 
  linear-gradient(to right, #55BFC8 0%,  #0079CB 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.mainBnr .actionWrap .keyWrap li:first-child {
  margin-left: 1rem;
}
.mainBnr .actionWrap .keyWrap li:last-child {
  margin-right: 1rem;

}
.mainBnr .actionWrap .keyWrap li {
  padding: 1%;
  width:100%;
}
.mainBnr .actionWrap .keyWrap li a{
  display: block;
  max-height: 130px;
  max-width: 130px;
  padding: 10%;
  border-radius: 100%;
  background-color: #F1F9FA;
}
.mainBnr .actionWrap .keyWrap li a img{
  width: 100%;
  max-height: 130px;
  display: block;
  padding:10%;
}
.mainBnr .actionWrap .infoWrap{
  height: 100%;
  width: 35%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
.mainBnr .actionWrap .infoWrap li{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45%;
  border: 3px solid transparent;
  border-radius: .8rem;
  background-image: linear-gradient(#fff, #fff), 
  linear-gradient(to right, #55BFC8 0%,  #0079CB 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  overflow: hidden;
}
.mainBnr .actionWrap .infoWrap li p {
  width: 100%;
  text-align: center;
}
.mainBnr .actionWrap .infoWrap li .dataTxt{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: #F1F9FA;
}
.mainBnr .actionWrap .infoWrap li .colorNum{
  color: #55BFC8;
}
.mainBnr .actionWrap .infoWrap li .dataNum{
  font-size: 2.4rem;
  font-weight: 800;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, .2);
}


.liveWrap{
  margin: 20vh auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.liveWrap > li{
  width: 49%;
}
.liveWrap li > p{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.375rem;
  border-bottom: .15rem solid #dddd;
  padding: .6rem;
}
.liveWrap li button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  border: .15rem solid #dddd;
  border-radius: .5rem;
  padding: .3rem .5rem;
  color: #666666;
}
.liveWrap li > div {
  height: 50vw;
  max-height: 700px;
  border-radius: .6rem;
  overflow: hidden;
  border: .15rem solid #dddd;
  margin-top: 2.5rem;
}
.liveWrap li > div ul{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  background-color: #fff;
  padding-top: 2.25rem;
  padding-bottom: 4.5rem;
}
.liveWrap li > div ul li.active {
  color: #222;
}
.liveWrap li > div ul li.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0; /* 텍스트에서 선까지의 거리 조정 */
  width: 100%;
  height: 3px; /* 선의 두께 */
  background: linear-gradient(to right, #F37335, #FDC830);
}
.liveWrap li > div ul li{
  color: #888;
  position: relative;
  padding-bottom: .3rem;
}
.liveWrap li:first-child > div > p {
  height: calc(100% - 210px);
}
.liveWrap li > div > p {
  height: 92%;
}
.liveWrap li > div > p img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.businessContainer{
}
.businessContainer h3{
  font-size: 2rem;
  font-weight: 600;
}
.businessContainer > p{
  font-size: 1rem;
  margin: 1rem 0;
}
.businessContainer > ul{
  display: flex;
  justify-content: space-between;
  gap: 4%;
}
.businessContainer > ul > li{
  width: 25%;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-size: 1rem;
  line-height: 2rem;
  padding: 4rem 0;
}
.businessContainer > ul > li img{
  width: 45%;
}
.businessContainer > ul > li span{
  font-size: 1.5rem;
  margin: 2.5rem 0 1rem;
}

.businessContainer .cubeInfo{
  margin-top: 15vh;
}
.businessContainer .cubeInfo > p{
  font-size: 2rem;
  margin-bottom: 1rem;
}
.businessContainer .cubeInfo ul{
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.businessContainer .cubeInfo ul li:nth-child(2){
  flex-flow: column-reverse;
}
.businessContainer .cubeInfo ul li{
  width: 32%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 2vw;
  overflow: hidden;
}
.businessContainer .cubeInfo ul li .infoImg {
  width: 100%; /* 원하는 너비 */
  aspect-ratio: 1 / 1; /* 원하는 비율, 1:1이면 정사각형 */
}
.businessContainer .cubeInfo ul li .infoTxt {
  text-align: center;
  background-color: #F1F9FA;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: #585858;
  font-size: 1.2rem;
  padding: 1.2rem;
  line-height: 1.5;
}
.businessContainer .cubeInfo ul li > p{
  height: 50%;
}
.businessContainer .cubeInfo ul li p img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.businessContainer .cubeInfo ul li span{
  margin-bottom: 1.5rem;
  font-weight: 700;
  font-size: 2.5rem;
}


@media screen and (max-width:1500px) {
  .liveWrap, .businessContainer, .businessModelContainer,
  .OnDeviceContainer, .ExperienceContainer, .ServiceContainer, .conect {
  padding: 0 5vw;
}
}
@media screen and (max-width:1024px) {
  .mainBnr {
    height: auto;
  }
  .mainBnr .actionWrap {
    margin-top: 5vw;
    position: unset;
    transform: none;
    flex-flow: column nowrap;
    gap: 2vw;
    max-height: 1000px;
    height: auto;
    padding: 0;
  }
  .mainBnr .actionWrap .infoWrap,
  .mainBnr .actionWrap .keyWrap {
    width: 100%;
  }
  .mainBnr .actionWrap .keyWrap {
    flex-flow: row wrap;
  }
  .mainBnr .actionWrap .keyWrap li {
    width: 30%;
    margin: 0 !important;
    display: flex;
    justify-content: center;
  }
  .mainBnr .actionWrap .infoWrap li {
    height: 50px;
  }
  .mainBnr h2 {
    font-size: 4rem;
  }
  .mainBnr > p {
    font-size: 1rem;
    max-width: 100%;
  }
  .liveWrap li > div ul {
    padding-top: 1vw;
    padding-bottom: 2vw;
  }
  .liveWrap li:first-child > div > p {
    height: 90%;
  }

  .businessContainer > ul {
    flex-flow: row wrap;

  }
  .businessContainer > ul > li {
    width: 48%;
  }
}


@media screen and (max-width:768px) {
  .liveWrap {
    flex-flow: column nowrap;
  }
  .liveWrap > li {
    width: 100%;
  }
}